export const defaultOptions = {
    radius: 20,
    fillColor: "#7da2ff",
    strokeColor: "#6f81ff",
    pathWidth: 1,
    pathColor: "red",
};

export const MAP_OPTIONS = {
    SRC: {
        radius: 7,
        fillColor: "#3dfa1f",
        strokeWidth: 0,
        strokeColor: "#3dfa1f",
        pathWidth: 1,
        pathColor: "#3dfa1f",
        tagColor: "#3dfa1f",
    },
    MAT: {
        radius: 7,
        fillColor: "#ff71f9",
        strokeWidth: 0,
        strokeColor: "#ff71f9",
        pathWidth: 1,
        pathColor: "#ff71f9",
        tagColor: "#ff71f9",
    },
    KAL: {
        radius: 7,
        fillColor: "#e9a60f",
        strokeWidth: 0,
        strokeColor: "#e9a60f",
        pathWidth: 1,
        pathColor: "#ff003b",
        tagColor: "#e9a60f",
    },
    ANG: {
        radius: 7,
        fillColor: "#7da2ff",
        strokeWidth: 0,
        strokeColor: "#6f81ff",
        pathWidth: 1,
        pathColor: "#73e0ff",
        tagColor: "#7da2ff",
    },
    HOR: {
        radius: 5,
        fillColor: "#055ff5",
        strokeWidth: 1,
        strokeColor: "#055ff5",
        pathWidth: 1.5,
        pathColor: "#05acf5",
        tagColor: "#055ff5",
    },
    PRX: {
        radius: 7,
        fillColor: "none",
        strokeWidth: 1,
        strokeColor: "#459a90",
        pathWidth: 1,
        pathColor: "#aa7de1",
        tagColor: "#459a90",
    },
    EMU: {
        radius: 7,
        fillColor: "none",
        strokeWidth: 1,
        strokeColor: "#cf9116",
        pathWidth: 1,
        pathColor: "#aa7de1",
        tagColor: "#cf9116",
    },
    CTE: {
        radius: 20,
        fillColor: "#479c99",
        strokeWidth: 1,
        strokeColor: "#479c99",
        pathWidth: 1,
        pathColor: "#f0542d33",
        tagColor: "#479c99",
    },
    RSSI: {
        radius: 20,
        fontSize: 11,
        fontColor: "#00bb84",
        fillColor: "#028487",
        fillOpacity: 0.1,
        strokeWidth: 1,
        strokeColor: "#028487",
        pathWidth: 1,
        pathColor: "#73e0ff",
        tagColor: "#7da2ff",
        timeColor: "#20a0d8"
    },
    WLK: {
        radius: 7,
        fillColor: "none",
        strokeWidth: 1,
        strokeColor: "#479c99",
        pathWidth: 6,
        pathColor: "#f0542d33",
        tagColor: "#479c99",
    },
    DNA: {
        radius: 7,
        fillColor: "#7da2ff",
        strokeWidth: 1,
        strokeColor: "#6f81ff",
        pathWidth: 2,
        pathColor: "#73e0ff",
        tagColor: "#7da2ff",
    },
    LOCATOR: {
        radius: 15,
        fontSize: 13,
        fontColor: "#83831c",
        fillColor: "#e19a61",
        inactiveFillColor: "#debfa6",
        activeColor: "#00bb84",
        inactiveColor: "#bcbcbc",
        strokeWidth: 1,
        strokeColor: "#ada49c",
        fillOpacity: "60%",
        tagColor: '#d19b70',
    },
    ACCESS_POINT: {
        radius: 15,
        fontSize: 13,
        fontColor: "#61a39c",
        fillColor: "#cb95cb",
        inactiveFillColor: "#d6c7d6",
        activeColor: "#00bb84",
        inactiveColor: "#bcbcbc",
        strokeWidth: 1,
        strokeColor: "#c3b7c3",
        fillOpacity: "60%",
        tagColor: '#c99ec9',
    },
    GEOFENCE: {
        fontSize: 15,
        fontColor: "#687b87",
        fillColors: ["#97bfdb", "#eac4d5", "#e8eac4", "#addbad", "#c4eae5", "#aa97db"],
        fillOpacity: "10%",
        strokeDashArray: 3,
        strokeWidth: 1,
        strokeColors: ["#97bfdb", "#eac4d5", "#e8eac4", "#addbad", "#c4eae5", "#aa97db"],
    },
}
